import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SnotifyService } from 'ng-snotify';

import { CoreService } from 'src/app/core/core.service';
import { Cart, Item } from 'src/app/interfaces/cart';
import { CartService } from 'src/app/services/cart.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cm-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements OnInit {
  @Input() id: number;
  @Input() black_friday: boolean = false;
  @Input() desconto_blackfriday: number = 0;
  @Input() em_destaque: boolean = false;
  @Input() slug: string;
  @Input() image: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() date: string;
  @Input() place: string;
  @Input() targetAudience: string;
  @Input() tag: any;
  @Input() isPromotion: boolean = false;
  @Input() descontoPromocao: number = 0;
  @Input() remainTime: number;
  @Input() remainText: string;
  @Input() isFull: boolean = false;
  @Input() isFinanciable: boolean = false;
  @Input() isPos: boolean = false;
  @Input() configTipoCursoId: number;
  @Input() resultCenterId: number;
  @Input() value: number;
  @Input() gt: number;
  @Input() allowCard: boolean = false;
  @Input() allowBillet: boolean = false;
  @Input() allowPix: boolean = false;
  @Input() classPayment: any[] = [];

  form: FormGroup;
  displayDialog: boolean = false;
  citiesNames: any;
  promotionSealImg: string;
  private _cities;
  private _selectedCityId;

  constructor (
    private _coreService: CoreService,
    private _snotifyService: SnotifyService,
    private _formBuilder: FormBuilder,
    private _cartService: CartService,
  ) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      course_id: [this.id, [Validators.required]],
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required]],
      city: [null, [Validators.required]],
      captcha: [null, [Validators.required]]
    });

    this.promotionSealImg = `${environment.amazon_s3}/img/promotion/Selos+de+desconto_${this.descontoPromocao}.png`;
  }

  // ----- PUBLIC METHODS
  toggleDialog(): void {
    this.displayDialog = !this.displayDialog;
  }

  captchaResponse(event): void {
    this.form.patchValue({captcha: event.response});
  }

  captchaExpires(): void {
    this.form.patchValue({captcha: null});
  }

  searchCity(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCities(params).subscribe(response => {
      this._cities = response;
      this.citiesNames = response.map(city => city.name + ` - `+ city.uf);
    })
  }

  defineCity(cityName: any): void {
    this._cities.find(city => {
      if(city.name + ` - `+ city.uf === cityName){
        this._selectedCityId = city.id;
        return true;
      }
    })
  }

  send(): void {
    let params = this.form.getRawValue();
    params['city'] = this._selectedCityId;
    this._coreService.courseQueue(params).subscribe(() => {
      this.displayDialog = false;
      this.form.reset();

      this._snotifyService.warning('Entraremos em contato quando próximas turmas abrirem para este curso.', 'Sucesso!', {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'centerTop'
      });
    })
  }

  addItemToCart() {

    const item:Item = {
      id: this.id,
      black_friday: this.black_friday,
      desconto_blackfriday: this.desconto_blackfriday,
      slug: this.slug,
      image: this.image,
      title: this.title,
      subtitle: this.subtitle,
      date: this.date,
      place: this.place,
      targetAudience: this.targetAudience,
      tag: this.tag,
      isPromotion: this.isPromotion,
      descontoPromocao:this.descontoPromocao,
      remainTime: this.remainTime,
      remainText: this.remainText,
      isFull: this.isFull,
      isFinanciable: this.isFinanciable,
      isPos: this.isPos,
      config_tipo_curso_id: this.configTipoCursoId,
      result_center_id: this.resultCenterId,
      value: this.value,
      gt: this.gt,
      allow_card: this.allowCard,
      allow_billet: this.allowBillet,
      allow_pix: this.allowPix,
      paymentTypes: this.classPayment,
    };
    this._cartService.addItemToCart(item);
  }
}
